<template>
  <div class="wizard">
    <v-stepper v-model="wizardStep" alt-labels flat style="height: 100%">
      <v-stepper-header class="mx-4">
        <v-stepper-step step="1" :complete="step1Complete">
          {{ $t('wizard.defineArea') }}
        </v-stepper-step>
        <v-divider />

        <v-stepper-step step="2" :complete="step2Complete">
          {{ $t('wizard.addMessage') }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step="3">
          {{ $t('wizard.prepareForSending') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-banner v-if="simulateMode" color="simulation" class="mx-4">
        <v-avatar slot="icon" color="simulation">
          <v-icon size="30">fal fa-exclamation-circle</v-icon>
        </v-avatar>
        {{ $t('wizard.simulationModeActiveAlert') }}
      </v-banner>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row dense class="full-height-row" :class="{ 'simulation-height': simulateMode }">
            <v-col cols="12" md="3" class="flex-grow-0 flex-shrink-1 ma-0">
              <v-card height="100%" class="pa-4" color="white">
                <v-switch
                  v-model="simulateMode"
                  :disabled="lockSimulationToggle || loadingSubscribers"
                  :label="$t('wizard.simulationMode')"
                  @click="beforeChangeSimulate"
                />
                <label class="mb-4">{{ $t('wizard.defineTargetDescription') }}</label>
                <v-autocomplete
                  v-model="selectedPredefinedArea"
                  :items="predefinedAreas"
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  clearable
                  aria-label="Predefined areas"
                  :label="$t('wizard.selectArea')"
                  class="my-4"
                  hide-details
                  :no-data-text="$t('wizard.noPredefinedAreas')"
                  :disabled="loadingSubscribers"
                />

                <v-divider class="my-2" />
                <div v-if="errorMsg">
                  <span class="error">{{ errorMsg }}</span>
                </div>
                <v-btn
                  block
                  rounded
                  color="secondary"
                  class="my-4"
                  :loading="loadingSubscribers"
                  :disabled="calculateSubscribersButtonDisabled"
                  :aria-label="$t('wizard.calculateSubscribers')"
                  @click="calculateSubscribers"
                  >{{ $t('wizard.calculateSubscribers') }}
                </v-btn>

                <v-skeleton-loader
                  v-if="loadingSubscribers"
                  type="card-heading, list-item-avatar@5"
                />
                <v-alert
                  v-if="showInvalidatedCalculateResultAlert"
                  class="pt-2"
                  dense
                  type="warning"
                >
                  {{ $t('wizard.invalidatedResult') }}
                </v-alert>
                <div v-if="byCountries">
                  <div class="iCountUp">
                    <v-icon class="countup-pre-text-icon">fal fa-users</v-icon>
                    <span>{{ $t('wizard.total') }}: </span>
                    <ICountUp
                      :delay="delay"
                      :end-val="$store.getters['alert/subscribersByCountryCount']"
                      :options="options"
                      class="subscriberCount no-wrap"
                    />
                  </div>
                  <h3 class="pt-4">
                    <span>{{ $t('wizard.nationalitiesInArea') }}</span>
                  </h3>
                  <v-data-table
                    :headers="countryListHeaders"
                    :items="byCountries.countries"
                    class="elevation-1"
                    :items-per-page="5"
                    :footer-props="{
                      itemsPerPageText: $t('rowCount'),
                      pageText: '{0}-{1} ' + $t('of') + ' {2}',
                      itemsPerPageAllText: $t('all'),
                    }"
                  >
                    <template #[`item.country.name`]="{ item }">
                      <div v-if="item.country" class="d-flex align-center">
                        <rounded-flag :country="item.country.code" size="40px" />
                        <span v-if="item.country.name === 'rest'" class="ml-4">{{
                          $t('wizard.unknownNationality')
                        }}</span>
                        <span v-else class="ml-4">{{ item.country.name }}</span>
                      </div>
                    </template>
                    <template #[`item.count`]="{ item }">
                      <div>
                        <span class="no-wrap">{{ item.count | separator }}</span>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" style="min-width: 100px" class="flex-grow-1 flex-shrink-0 ma-0">
              <v-card width="100%" min-height="50vh" height="100%">
                <Map
                  ref="map"
                  class="map"
                  :rounded-corners="true"
                  :markers="markers"
                  :polygons="alertTarget"
                  width="100%"
                  height="100%"
                  map-type="streets"
                  :draw-tools="drawTools"
                  :limits="customerLimits"
                  :predefined-area="alertTarget"
                  :search-control="true"
                  @ready="mapReady"
                  @finishedDrawing="mapFinishedDrawing"
                  @clearedDrawing="mapClearedDrawing"
                  @startPolygon="startPolygon"
                />
                <div
                  v-if="cells"
                  class="missing-cell-info"
                  v-html="
                    $t('wizard.missingTeliaCellInfo', {
                      msg: teliaCellSubscribers,
                    })
                  "
                />
                <v-overlay absolute opacity="0.6" :value="loadingSubscribers">
                  <div class="d-flex flex-column align-center">
                    <h2>{{ $t('wizard.calculating') }}</h2>
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      color="white"
                      class="mt-4"
                      indeterminate
                      :aria-label="$t('wizard.calculating')"
                    />
                  </div>
                </v-overlay>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-0 my-4">
            <v-btn large @click="abortWizard">{{ $t('cancel') }}</v-btn>
            <v-spacer />
            <v-btn color="primary" large :disabled="step1NextButtonDisabled" @click="validateStep1"
              >{{ $t('next') }}<v-icon right>fal fa-chevron-circle-right</v-icon></v-btn
            >
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row dense class="full-height-row" :class="{ 'simulation-height': simulateMode }">
            <v-col cols="12" md="7" class="flex-grow-0 flex-shrink-1 ma-0">
              <v-card class="pa-4" height="100%">
                <v-card class="pa-0 px-4" flat>
                  <h3>{{ $t('wizard.messageOriginator') }}</h3>
                  <v-text-field
                    v-model="messageOriginator"
                    dense
                    :label="$t('wizard.originator')"
                    outlined
                    required
                    maxlength="11"
                    counter
                    :error-messages="messageOriginatorValidationError"
                    class="mt-2 originator-input"
                  />
                </v-card>
                <v-card class="pa-0 px-4" flat>
                  <h3 class="mb-4">{{ $t('wizard.messageSelectorHeader') }}</h3>
                  <template v-if="templates">
                    <v-autocomplete
                      v-model="selectedTemplate"
                      :items="templates"
                      dense
                      item-text="name"
                      item-value="id"
                      return-object
                      clearable
                      outlined
                      aria-label="Text templates"
                      :label="$t('wizard.selectFromTextTemplate')"
                      class="my-2 mb-4 text-template-selector"
                      width="100px"
                      hide-details
                      :no-data-text="$t('wizard.noTemplatematches')"
                      :disabled="templates.length <= 0"
                    />
                  </template>
                </v-card>
                <v-divider class="my-4" />
                <v-card class="pa-2 px-4">
                  <h4 class="mb-2">
                    <div v-if="countryMessages.length > 0">
                      <span class="ml-1">{{ $t('wizard.messageToOthers') }} </span>
                      <ICountUp
                        :delay="delay"
                        :end-val="defaultMessageReceiversCount"
                        :options="options"
                      />
                      <span>{{ $t('recipients') }}</span>
                    </div>
                    <div v-else>
                      <span>{{ $t('wizard.messageToAll') }}</span>
                      <ICountUp
                        :delay="delay"
                        :end-val="defaultMessageReceiversCount"
                        :options="options"
                      />
                      <span>{{ $t('recipients') }}</span>
                    </div>
                  </h4>
                  <v-textarea
                    :key="wizardStep"
                    v-model="defaultMessage"
                    outlined
                    auto-grow
                    autofocus
                    rows="2"
                    row-height="30"
                    :label="$t('wizard.message')"
                    color="accent"
                    hide-details="auto"
                    :error="!hasValidDefaultMessage"
                  />
                  <p
                    class="mb-0 mt-1 mr-1 caption text-right"
                    :class="!hasValidDefaultMessage ? 'error--text' : ''"
                  >
                    {{ characterCountText }}
                  </p>
                  <v-alert v-if="exceededCharactersCount" type="error" dense>
                    {{
                      $t('wizard.messageTooLong', {
                        maxLength: defaultMessageMaxLength,
                      })
                    }}
                  </v-alert>
                  <v-alert v-if="defaultMessageEncoding === 'ucs-2'" dense text type="warning">
                    {{ $t('wizard.messageContainsSpecialCharacter') }}
                  </v-alert>
                </v-card>
                <div v-if="byCountries">
                  <v-card v-if="countryMessages.length > 0" class="pa-2 mt-2">
                    <h4 class="mb-2 ml-2">
                      {{ $t('wizard.countrySpesificMessages') }}
                    </h4>
                    <div
                      v-for="msg in countryMessages"
                      :key="msg.country.code"
                      class="country-msg-card"
                    >
                      <country-message-input
                        :item="msg"
                        :flat="true"
                        @validatedMessage="validatedMessage"
                      />
                    </div>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" style="min-width: 100px" class="flex-grow-1 flex-shrink-0 ma-0">
              <v-card v-if="byCountries" height="100%">
                <h3 class="px-4 pt-4">
                  {{ $t('wizard.nationalitiesInArea') }}
                </h3>
                <p class="px-4">
                  {{ $t('wizard.addCountrySpecificMessages') }}
                </p>
                <v-data-table
                  :headers="countryMessageListHeaders"
                  :items="byCountries.countries"
                  elevation:1
                  class="full-heigh-tabel"
                  :items-per-page="10"
                  :footer-props="{
                    itemsPerPageText: $t('rowCount'),
                    pageText: '{0}-{1} ' + $t('of') + ' {2}',
                    itemsPerPageAllText: $t('all'),
                  }"
                >
                  <template #[`item.country.name`]="{ item }">
                    <div v-if="item.country" class="d-flex align-center">
                      <rounded-flag :country="item.country.code" size="40px" />
                      <span v-if="item.country.name === 'rest'" class="ml-4">{{
                        $t('wizard.unknownNationality')
                      }}</span>
                      <span v-else class="ml-4">{{ item.country.name }}</span>
                    </div>
                  </template>
                  <template #[`item.count`]="{ item }">
                    <div>
                      <span class="ml-4">{{ item.count | separator }}</span>
                    </div>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <div v-if="item.country.code != 'rest' && countryMessageExists(item.country)">
                      <v-icon color="green"> fal fa-clipboard-check </v-icon>
                    </div>
                    <div
                      v-else-if="item.country.code != 'rest'"
                      class="selectable"
                      @click="editCountryMessageItem(item)"
                    >
                      <v-icon color="primary"> fa fa-plus-circle </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-0 my-4">
            <v-btn large @click="abortWizard">{{ $t('cancel') }}</v-btn>
            <v-spacer />
            <v-btn color="primary" large class="mr-4" @click="wizardStep--"
              ><v-icon left>fal fa-chevron-circle-left</v-icon>{{ $t('previous') }}</v-btn
            >
            <v-btn color="primary" :disabled="step2NextButtonDisabled" large @click="validateStep2"
              >{{ $t('next') }}<v-icon right>fal fa-chevron-circle-right</v-icon></v-btn
            >
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-row dense class="full-height-row" :class="{ 'simulation-height': simulateMode }">
            <v-col cols="12" lg="4" md="6" xl="3">
              <v-card height="100%" class="pa-4" color="white">
                <h3 class="mb-4">{{ $t('wizard.timeForSending') }}</h3>
                <v-switch v-model="sendImmediately" :label="$t('wizard.startImmediately')" />
                <v-row v-if="!sendImmediately">
                  <v-col cols="12" md="8" sm="12">
                    <label>{{ $t('wizard.startAlertAtFollowingTime') }}</label>
                    <Datepicker
                      id="startTime"
                      v-model="alertStartTime"
                      :min-date="startMin"
                      :max-date="startMax"
                      :label="$t('wizard.startTime')"
                      format="YYYY-MM-DD HH:mm"
                      formatted="dddd DD.MM YYYY HH:mm"
                      :locale="datepickerLocale"
                      :first-day-of-week="1"
                      no-label
                      color="#457B9D"
                    />
                  </v-col>
                </v-row>
                <v-switch v-model="recurringAlert" :label="$t('wizard.alertPeopleEnteringArea')" />
                <v-row v-if="recurringAlert">
                  <v-col cols="12" md="8" sm="12">
                    <label>{{ $t('wizard.keepAlertActiveUntil') }}</label>
                    <Datepicker
                      id="endTime"
                      v-model="alertEndTime"
                      :min-date="endMin"
                      :max-date="endMax"
                      :label="$t('wizard.endTime')"
                      format="YYYY-MM-DD HH:mm"
                      formatted="dddd DD.MM YYYY HH:mm"
                      :locale="datepickerLocale"
                      :first-day-of-week="1"
                      no-label
                      color="#457B9D"
                    />
                  </v-col>
                  <label class="ma-4 mt-0">{{ $t('wizard.recurrenceDisclaimer') }}</label>
                </v-row>
                <v-divider class="mt-4" />
                <v-row>
                  <v-col cols="12">
                    <v-card-title class="pa-0 py-4">{{
                      $t('wizard.sendOnlyToIncludeList')
                    }}</v-card-title>

                    <v-autocomplete
                      v-model="selectedIncludeList"
                      :items="subscriberLists"
                      :item-text="(item) => item.name + ' (' + item.subscribersCount + ')'"
                      dense
                      return-object
                      outlined
                      clearable
                      aria-label="this.$t('wizard.selectIncludeList')"
                      :label="$t('wizard.selectIncludeList')"
                      class="my-2"
                      hide-details
                      :no-data-text="$t('wizard.noIncludeLists')"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" xl="3">
              <v-card height="100%" class="pa-4">
                <h3 class="mb-4">{{ $t('wizard.summary') }}</h3>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="alertName"
                      v-model="alertName"
                      dense
                      :label="$t('wizard.alertName')"
                      outlined
                      required
                      :error-messages="nameValidationError"
                      class="my-2"
                      :hint="$t('wizard.alertNameDescription')"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="summaryCounter mb-4">
                      <v-icon>fal fa-users</v-icon><br />

                      <ICountUp
                        v-if="!selectedIncludeList"
                        class="counter"
                        :delay="delay"
                        :end-val="$store.getters['alert/subscribersByCountryCount']"
                        :options="options"
                      />
                      <ICountUp
                        v-else
                        class="counter"
                        :delay="delay"
                        :end-val="selectedIncludeList.subscribersCount"
                        :options="options"
                      />
                      <p>{{ $t('wizard.estimatedSubscribers') }}</p>
                      <v-alert v-if="!sendImmediately" color="info" dense text class="mx-2">{{
                        $t('wizard.plannedCountDisclaimer')
                      }}</v-alert>
                    </div>
                  </v-col>
                  <v-spacer />
                </v-row>
                <h3>{{ $t('wizard.defaultMessage') }}</h3>
                <p class="default-message-card mt-1 mb-4">
                  {{ defaultMessage }}
                </p>
                <v-expansion-panels flat :value="0">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-0">
                      <h3>
                        {{
                          $t('wizard.countrySpecificMessagesCount', {
                            count: countryMessages.length,
                          })
                        }}
                      </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <div v-for="(item, index) in countryMessages" :key="index" class="pb-4">
                        <v-row class="py-2">
                          <rounded-flag :country="item.country.code" size="20px" />
                          <span class="ml-2">{{ item.country.name }}: </span>
                          <v-tooltip
                            top
                            max-width="400px"
                            color="rgba(0, 0, 0, 0.9)"
                            open-on-hover
                            open-delay="500"
                          >
                            <template #activator="{ on, attrs }">
                              <span v-bind="attrs" class="truncate" v-on="on">{{
                                item.message
                              }}</span>
                            </template>
                            <span class="text-word-break">{{ item.message }}</span>
                          </v-tooltip>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" lg="4" xl="6">
              <v-card min-height="60vh" height="100%">
                <Map
                  ref="summaryMap"
                  :rounded-corners="true"
                  :center="summaryMapCenter"
                  :markers="markers"
                  width="100%"
                  height="100%"
                  map-type="streets"
                  :draw-tools="false"
                  :predefined-area="alertTarget"
                  :clusters-muted="!!selectedIncludeList"
                />
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-0 my-4">
            <v-btn large @click="abortWizard">{{ $t('cancel') }}</v-btn>
            <v-spacer />
            <v-btn color="primary" large class="mr-4" @click="wizardStep--"
              ><v-icon left>fal fa-chevron-circle-left</v-icon>{{ $t('previous') }}</v-btn
            >
            <template v-if="sendImmediately">
              <v-btn
                v-if="simulateMode"
                color="simulation"
                light
                large
                :loading="sendingAlert"
                @click="sendAlert"
              >
                {{ $t('wizard.sendSimulated')
                }}<v-icon right class="send-icon">fal fa-paper-plane </v-icon>
              </v-btn>
              <v-btn
                v-else
                color="third"
                dark
                large
                class="px-8"
                :loading="sendingAlert"
                @click="sendAlert"
              >
                {{ $t('wizard.send')
                }}<v-icon right class="send-icon">fal fa-paper-plane</v-icon></v-btn
              >
            </template>
            <template v-else>
              <v-btn
                v-if="simulateMode"
                color="simulation"
                light
                large
                :loading="sendingAlert"
                @click="sendAlert"
              >
                {{ $t('wizard.sendPlannedSimulated')
                }}<v-icon right class="send-icon">fal fa-paper-plane </v-icon>
              </v-btn>
              <v-btn
                v-else
                color="third"
                dark
                large
                class="px-8"
                :loading="sendingAlert"
                @click="sendAlert"
              >
                {{ $t('wizard.sendPlanned')
                }}<v-icon right class="send-icon">fal fa-paper-plane</v-icon></v-btn
              >
            </template>
          </v-row>
        </v-stepper-content></v-stepper-items
      >
    </v-stepper>
    <Confirm ref="confirm" />
  </div>
</template>
<script>
import RoundedFlag from '@/components/RoundedFlag.vue';
import CountryMessageInput from '@/components/CountryMessageInput.vue';
import Map from '@/components/Map';
import ICountUp from 'vue-countup-v2';
import moment from 'moment';
import Datepicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import {
  convertToMultiPolygon,
  convertFromPredefinedAreaToLeafletFormat,
} from '@/util/polygonConverter';
import { textIsUcs2, extendedCharactersCount, isValidSmsOriginator } from '@/util/messageHelper';
import Confirm from '@/components/dialog/Confirm.vue';
import * as numberTools from '@/util/numberTools';

export default {
  name: 'SendAlertWizard',
  components: {
    Map,
    RoundedFlag,
    ICountUp,
    Datepicker,
    CountryMessageInput,
    Confirm,
  },

  data: (self) => ({
    sendImmediately: true,
    recurringAlert: false,
    messageOriginator: self.$store.state.user.profile?.smsOriginator ?? '',
    wizardStep: 1,
    nameValidationError: '',
    errorMsg: null,
    customerLimits: null,
    alertStartTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    alertEndTime: moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'),
    center: {
      lat: 60,
      lng: 5,
    },
    summaryMapCenter: {
      lat: 60,
      lng: 5,
    },
    drawTools: true,

    loadingSubscribers: false,
    sendingAlert: false,
    calculateDisabled: true,
    defaultMessageMaxLength: 0,
    defaultMessageErrorState: false,
    defaultMessageErrorMsg: '',
    messageOriginatorValidationError: '',
    messageOriginatorErrorState: false,
    delay: 0,
    endVal: 0,
    options: {
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: '.',
      prefix: '',
      suffix: '',
    },
    showInvalidatedCalculateResultAlert: false,
    alertHasErrors: false,
    defaultMessageSmsCount: 0,
    step1Complete: false,
    step2Complete: false,
    defaultMessageTextLength: 0,
    lockSimulationToggle: false,
    selectedTemplate: null,
    now: moment(),
    nowUpdateInterval: null,
    hasInvalidCountryMessage: false,
  }),
  computed: {
    datepickerLocale() {
      let locale = this.$store.state.user.locale;
      if (locale === 'no') return 'nb';
      return locale;
    },
    countryListHeaders() {
      return [
        { text: this.$t('wizard.country'), value: 'country.name' },
        { text: this.$t('wizard.count'), value: 'count', align: 'right' },
      ];
    },
    countryMessageListHeaders() {
      return [
        { text: '', value: 'actions', sortable: false, width: '20px' },
        { text: this.$t('wizard.country'), value: 'country.name' },
        { text: this.$t('wizard.count'), value: 'count', align: 'right' },
      ];
    },
    profile() {
      return this.$store.state.user.profile;
    },
    teliaCellSubscribers() {
      return this.$store.getters['alert/getTotalTeliaSubscribers'];
    },
    simulateMode: {
      get() {
        return this.$store.state.user.simulateMode;
      },
      set(value) {
        this.$store.commit('user/setSimulateMode', value);
      },
    },
    alertName: {
      get() {
        return this.$store.state.alert.alertName;
      },
      set(value) {
        this.$store.commit('alert/setAlertName', value);
      },
    },
    selectedPredefinedArea: {
      get() {
        return this.$store.state.alert.selectedPredefinedArea;
      },
      set(value) {
        this.$store.commit('alert/setSelectedPredefinedArea', value);
      },
    },
    selectedIncludeList: {
      get() {
        return this.$store.state.alert.selectedIncludeList;
      },
      set(value) {
        this.$store.commit('alert/setSelectedIncludeList', value);
      },
    },
    markers: {
      get() {
        return this.$store.state.alert.cellMarkers;
      },
      set(value) {
        this.$store.commit('alert/setCellMarkers', value);
      },
    },
    countryMessages() {
      return this.$store.state.alert.countryMessages;
    },
    byCountries: {
      get() {
        return this.$store.state.alert.countryResult;
      },
      set(value) {
        this.$store.commit('alert/setCountryResult', value);
      },
    },
    cells() {
      return this.$store.state.alert.cellResult;
    },
    predefinedAreas() {
      return this.$store.state.user.predefinedAreas;
    },
    subscriberLists() {
      return this.$store.state.alert.subscriberLists;
    },
    alertTarget: {
      get() {
        return this.$store.state.alert.alertTarget;
      },
      set(value) {
        if (value) this.$store.dispatch('alert/storeAlertTarget', value);
      },
    },
    defaultMessageReceiversCount() {
      let defaultCount = 0;
      if (this.byCountries !== null) {
        defaultCount = this.byCountries.total;
        let countrySpecificReceivers = this.countryMessages.reduce((n, { count }) => n + count, 0);
        defaultCount = defaultCount - countrySpecificReceivers;
      }
      return defaultCount;
    },
    countrySpecificMessageReceiversCount() {
      let countrySpecificReceivers = 0;
      if (this.byCountries !== null) {
        countrySpecificReceivers = this.countryMessages.reduce((n, { count }) => n + count, 0);
      }
      return countrySpecificReceivers;
    },
    form() {
      return {
        alertName: this.alertName,
      };
    },
    calculateSubscribersButtonDisabled() {
      if (this.alertTarget && this.alertTarget.length > 0) return false; // valid params for starting drawing

      return true;
    },
    step1NextButtonDisabled() {
      if (this.byCountries && !this.showInvalidatedCalculateResultAlert) return false;
      return true;
    },
    defaultMessage: {
      get() {
        return this.$store.state.alert.defaultMessage;
      },
      set(value) {
        this.$store.commit('alert/setDefaultMessage', value);
      },
    },

    defaultMessageEncoding: {
      get() {
        return this.$store.state.alert.defaultMessageEncoding;
      },
      set(value) {
        if (this.$store.state.alert.defaultMessageEncoding != value) {
          this.$store.commit('alert/setDefaultMessageEncoding', value);
        }
      },
    },
    hasValidDefaultMessage() {
      if (
        this.defaultMessageTextLength >= 3 &&
        this.defaultMessageTextLength <= this.defaultMessageMaxLength
      ) {
        return true;
      } else {
        return false;
      }
    },
    step2NextButtonDisabled() {
      if (!this.hasValidDefaultMessage) return true;
      else if (this.countryMessages.length > 0 && this.hasInvalidCountryMessage) {
        return true;
      } else {
        return false;
      }
    },
    exceededCharactersCount() {
      if (this.defaultMessageTextLength > this.defaultMessageMaxLength) {
        return true;
      } else {
        return false;
      }
    },
    characterCountText() {
      return (
        this.$t('characters') +
        this.defaultMessageTextLength +
        ' / ' +
        this.defaultMessageMaxLength +
        ' (' +
        this.defaultMessageSmsCount +
        ' SMS)'
      );
    },
    templates() {
      return this.$store.state.textTemplate.templates;
    },
    allCountries() {
      return this.$store.state.textTemplate.countries;
    },
    startMin() {
      return this.now.format('YYYY-MM-DD HH:mm:ss');
    },
    startMax() {
      return this.now.add(1, 'week').format('YYYY-MM-DD HH:mm:ss');
    },
    endMin() {
      return moment(this.alertStartTime).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
    },
    endMax() {
      return moment(this.alertStartTime).add(1, 'month').format('YYYY-MM-DD HH:mm:ss');
    },
  },
  watch: {
    alertStartTime() {
      if (this.recurringAlert) {
        if (moment(this.alertEndTime).isAfter(this.endMax)) this.alertEndTime = this.endMax;
        else if (moment(this.alertEndTime).isBefore(this.endMin)) this.alertEndTime = this.endMin;
      }
    },
    selectedPredefinedArea() {
      this.$refs.map.clearDrawing();
      this.clearDrawingAndResult();
      this.$store.dispatch('alert/storeAlertTarget', null);
      this.alertTarget = null;

      // this.alertTarget = null;
      if (this.selectedPredefinedArea) {
        this.clearDrawingAndResult();
        let convertedPolygon = convertFromPredefinedAreaToLeafletFormat(
          this.selectedPredefinedArea.multiPolygon,
        );
        this.$store.dispatch('alert/storeAlertTarget', convertedPolygon);
        this.alertTarget = convertedPolygon;
        this.calculateDisabled = false;
        // TODO: Disable drawing mode, enter edit mode
        // console.info("Stop drawing mode");
        //  this.$refs.map.stopDrawing();
        // const mapComponent = this.$refs.map;
        // const map = mapComponent.map;
        // let currentLayer = null;
        // map.eachLayer((layer) => {
        //   console.info(layer);
        //   if (layer.id === 'predefinedArea' ) {
        //     currentLayer = layer;
        //   }
        // });
        // this.$refs.map.enableEdit();
      }
    },
    defaultMessage() {
      this.remainingCharactersCount();
      this.defaultMessageErrorState = false;
      this.defaultMessageErrorMsg = '';
    },
    wizardStep() {
      if (this.wizardStep == 3) {
        setTimeout(() => {
          this.$refs.summaryMap?.redraw();
          this.$refs.summaryMap?.fitToPredefinedArea();
        }, 100);
      }
    },
    sendImmediately() {
      if (!this.sendImmediately) this.alertStartTime = moment().format('YYYY-MM-DD HH:mm:ss');
    },
    recurringAlert() {
      this.alertEndTime = moment(this.alertStartTime).add(1, 'hours');
    },
    messageOriginator() {
      if (this.messageOriginator.length === 0) {
        this.messageOriginatorErrorState = true;
        this.messageOriginatorValidationError = this.$t('wizard.enterOriginator');
      } else if (this.messageOriginator.length < 3 || this.messageOriginator > 11) {
        this.messageOriginatorErrorState = true;
        this.messageOriginatorValidationError = this.$t('wizard.invalidSmsOriginatorLength');
      } else if (!isValidSmsOriginator(this.messageOriginator)) {
        this.messageOriginatorErrorState = true;
        this.messageOriginatorValidationError = this.$t('wizard.invalidSmsOriginatorCharacter');
      } else {
        this.messageOriginatorErrorState = false;
        this.messageOriginatorValidationError = '';
      }
    },
    selectedTemplate() {
      if (!this.selectedTemplate) {
        // cleared selection
        this.defaultMessage = '';
        this.countryMessages.forEach((msg) => {
          this.$store.dispatch('alert/removeCountryMessage', msg);
        });
      } else {
        // Check if user har written something that will be overwritten
        if (this.defaultMessage.length > 0) {
          this.$refs.confirm
            .open(
              this.$t('areYouSure'),
              this.$t('youWillLoseWrittenMessages'),
              this.$t('ok'),
              this.$t('cancel'),
              { color: 'third', width: '400px' },
            )
            .then((confirm) => {
              if (confirm) {
                this.getTextTemplate(this.selectedTemplate.id);
              }
            });
        } else {
          this.getTextTemplate(this.selectedTemplate.id);
        }
      }
    },
    now() {
      if (moment(this.alertStartTime).isBefore(this.now)) {
        this.alertStartTime = this.now.format('YYYY-MM-DD HH:mm:ss');
      }
    },
    alertName() {
      if (this.alertName.length >= 3 && this.alertName.length <= 50) {
        this.nameValidationError = '';
      } else {
        this.nameValidationError = this.$t('wizard.enterAlertName');
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.nowUpdateInterval);
  },
  mounted() {
    this.goTo({ lat: 60.35, lng: 5.25 });
    // this.alertStartTime = moment().format('YYYY-MM-DD HH:mm');
    this.alertEndTime = moment().add(1, 'hours');
    this.$store.dispatch('user/getPredefinedAreas');
    this.$store.dispatch('alert/getSubscriberLists');

    if (this.$store.state.alert.defaultMessageEncoding == 'ucs-2')
      this.defaultMessageMaxLength = 417;
    else this.defaultMessageMaxLength = 953;

    if (this.$store.state.alert.defaultMessage.length > 0) {
      this.defaultMessage = this.$store.state.alert.defaultMessage;
      this.remainingCharactersCount();
    }
    if (this.$store.state.user.apiStatus.forceSimulation) {
      this.simulateMode = true;
      this.lockSimulationToggle = true;
    }
    if (
      !this.$store.state.textTemplate.templates ||
      this.$store.state.textTemplate.templates.length == 0
    ) {
      this.$store.dispatch('textTemplate/getTextTemplates');
    }

    this.nowUpdateInterval = setInterval(() => {
      this.now = moment();
    }, 1000);
  },
  methods: {
    validatedMessage() {
      let hasInvalidCountry = false;
      for (let i = 0; i < this.countryMessages.length; i++) {
        const parsedCountryMessage = JSON.parse(JSON.stringify(this.countryMessages[i]));
        if (parsedCountryMessage.isValid === false) {
          hasInvalidCountry = true;
          break;
        }
      }
      this.hasInvalidCountryMessage = hasInvalidCountry;
    },
    async beforeChangeSimulate() {
      if (this.byCountries && this.cells) {
        const confirm = await this.$refs.confirm.open(
          this.$t('wizard.clearCalculations'),
          this.$t('wizard.clearCalculationsMsg'),
          this.$t('ok'),
          this.$t('cancel'),
          { color: 'warning', width: '400px' },
        );
        if (confirm) {
          this.clearDrawingAndResult();
        } else {
          this.simulateMode = !this.simualteMode;
        }
      }
    },
    getDefaultMessageTextLength() {
      return this.defaultMessageTextLength;
    },
    abortWizard() {
      this.$router.push({ name: 'Dashboard' });
    },
    mapReady() {
      let convertedLimits = convertFromPredefinedAreaToLeafletFormat(
        this.profile.customer.areaLimits,
      );
      this.customerLimits = convertedLimits;
      // TODO: Enable draw mode
      // if (!this.selectedPredefinedArea) {
      //   console.info('mapReady, no selected area, enable draw mode');
      //   this.startDrawing();
      // }
    },
    countryMessageExists(country) {
      if (this.countryMessages.some((data) => data.country.code === country.code)) return true;
      else return false;
    },
    getCountryMessage(country) {
      const cm = this.countryMessages.find((c) => c.country.code == country.code);
      return cm ? cm.message : '';
    },
    goTo(latLng) {
      this.center = latLng;
      this.summaryMapCenter = latLng;
    },
    calculateSubscribers() {
      this.loadingSubscribers = true;
      this.showInvalidatedCalculateResultAlert = false;

      this.errorMsg = null;
      if (this.byCountries) {
        this.clearDrawingAndResult();
      }

      const convertedPolygon = convertToMultiPolygon(this.alertTarget);
      const body = {
        multiPolygon: convertedPolygon,
        simulate: this.simulateMode,
      };
      this.$store
        .dispatch('alert/calculateCountries', body)
        .then(() => {
          this.loadingSubscribers = false;
        })
        .catch((error) => {
          alert(error);
          this.loadingSubscribers = false;
        });

      this.$store
        .dispatch('alert/calculateCells', body)
        .then(() => {
          const tempMarkers = [];
          for (let i = 0; i < this.cells.cells.length; i++) {
            const marker = {
              latLng: {
                lat: this.cells.cells[i].latitude,
                lng: this.cells.cells[i].longitude,
              },
              text: this.cells.cells[i].count,
              id: i,
            };
            tempMarkers.push(marker);
          }
          this.markers = tempMarkers;
        })
        .catch((error) => {
          alert(error);
        });
    },
    mapFinishedDrawing(polygons) {
      // not multipolygon, but single
      if (this.byCountries) {
        this.showInvalidatedCalculateResultAlert = true;
      }
      this.calculateDisabled = false;
      this.alertTarget = polygons;
    },
    mapClearedDrawing() {
      this.$store.dispatch('alert/resetCellResult');
      this.$store.dispatch('alert/resetCountryResult');
      this.markers = null;
    },
    startPolygon() {
      this.selectedPredefinedArea = null;
      this.clearDrawingAndResult();
      this.$store.dispatch('alert/storeAlertTarget', null);
    },
    clearDrawingAndResult() {
      // this.$refs.map.stopDrawing();
      this.$store.dispatch('alert/resetCellResult');
      this.$store.dispatch('alert/resetCountryResult');
      this.markers = null;
    },
    startDrawing() {
      this.selectedPredefinedArea = null;
      this.clearDrawingAndResult();
      this.$store.dispatch('alert/storeAlertTarget', null);
      this.$refs.map.startPolygon();
    },
    editCountryMessageItem(item) {
      this.$store.dispatch('alert/setCountryMessage', item);
    },
    validateStep1() {
      if (this.byCountries && !this.showInvalidatedCalculateResultAlert) {
        this.step1Complete = true;
        this.wizardStep++;
        return;
      }
      if (this.alertTarget) {
        this.calculateSubscribers();
        this.step1Complete = true;
        this.wizardStep++;
        return;
      } else {
        this.errorMsg = this.$t('wizard.youMustSelectAlertArea');
        return false;
      }
    },
    validateStep2() {
      if (this.messageOriginatorErrorState) return false;

      let hasCountryError = false;
      if (this.countryMessages.length > 0) {
        this.countryMessages.every((msg) => {
          if (!msg.isValid) {
            hasCountryError = true;
            return false;
          } else return true;
        });
      }
      if (hasCountryError === true) return false;

      if (this.defaultMessageTextLength > 0) {
        this.defaultMessageErrorMsg = '';
        this.defaultMessageErrorState = false;
        this.step2Complete = true;
        this.wizardStep++;
      } else {
        this.defaultMessageErrorMsg = this.$t('wizard.enterMessageText');
        this.defaultMessageErrorState = true;
      }
    },
    sendAlert() {
      if (this.alertName.length < 3 || this.alertName.length > 50) {
        this.nameValidationError = this.$t('wizard.enterAlertName');
        return;
      } else {
        this.nameValidationError = null;
      }

      let estimatedSubsCount = this.$store.getters['alert/subscribersByCountryCount'];
      if (this.selectedIncludeList) {
        estimatedSubsCount = this.selectedIncludeList.subscribersCount;
      }

      let confirmMsg = this.$t('wizard.pressOkToSendTo', {
        count: numberTools.formatNumber(estimatedSubsCount),
      });
      let confirmHeader = this.$t('wizard.sendAlertConfirmationHeader');
      let confirmColor = 'third';
      if (this.simulateMode) {
        confirmMsg = this.$t('wizard.pressOkToSendSimulatedTo', {
          count: numberTools.formatNumber(estimatedSubsCount),
        });
        confirmHeader = this.$t('wizard.sendSimulatedAlertConfirmationHeader');
        confirmColor = 'simulation';
      }
      if (!this.sendImmediately) {
        confirmMsg = this.$t('wizard.pressOkToSendPlannedTo', {
          count: numberTools.formatNumber(estimatedSubsCount),
        });
        confirmHeader = this.$t('wizard.sendPlannedAlertConfirmationHeader');
        confirmColor = 'third';
        if (this.simulateMode) {
          confirmMsg = this.$t('wizard.pressOkToSendPlannedSimulatedTo', {
            count: numberTools.formatNumber(estimatedSubsCount),
          });
          confirmHeader = this.$t('wizard.sendPlannedSimulatedAlertConfirmationHeader');
          confirmColor = 'simulation';
        }
      }
      this.$refs.confirm
        .open(confirmHeader, confirmMsg, this.$t('ok'), this.$t('cancel'), {
          color: confirmColor,
          width: '400px',
        })
        .then((confirm) => {
          if (confirm) {
            this.sendingAlert = true;
            let convertedMsgs = [];
            let msgs = this.countryMessages;
            for (let i = 0; i < msgs.length; i++) {
              convertedMsgs.push({
                countryCode: msgs[i].country.code,
                content: msgs[i].message,
                encoding: msgs[i].encoding,
              });
            }

            const dateTimeOffsetFormat = 'YYYY-MM-DDTHH:mm:ssZ';
            const start = moment(this.alertStartTime).utc().format(dateTimeOffsetFormat);
            const end = moment(this.alertEndTime).utc().format(dateTimeOffsetFormat);

            let body = {
              name: this.alertName,
              defaultMessage: this.defaultMessage,
              defaultMessageEncoding: this.defaultMessageEncoding,
              messages: convertedMsgs,
              multiPolygon: convertToMultiPolygon(this.alertTarget),
              estimatedSubscriberCount: estimatedSubsCount,
              simulate: this.simulateMode,
              includeListId: this.selectedIncludeList?.id ?? null,
              start: this.sendImmediately ? null : start,
              end: this.recurringAlert ? end : null,
              isRecurring: this.recurringAlert,
              messageOriginator: this.messageOriginator,
            };

            this.$store
              .dispatch('alert/sendAlert', body)
              .then((result) => {
                this.resetState();
                this.sendingAlert = false;
                this.$router.push({
                  name: 'AlertDetails',
                  params: { alertId: result.data.id.toString() },
                });
              })
              .catch((error) => {
                this.sendingAlert = false;
                alert(error);
              });
          }
        });
    },
    remainingCharactersCount() {
      if (!this.defaultMessage || this.defaultMessage.length === 0) {
        this.defaultMessageTextLength = 0;
        this.defaultMessageEncoding = 'gsm-7';
        this.defaultMessageMaxLength = 960 - 6 * 7;
        return;
      }
      let isUcs2 = false;
      if (this.defaultMessage.length > 0) {
        isUcs2 = textIsUcs2(this.defaultMessage);
      }

      this.defaultMessageTextLength = this.defaultMessage.length;
      if (isUcs2) {
        this.defaultMessageEncoding = 'ucs-2';
        this.defaultMessageMaxLength = 420 - 6 * 3;
      } else {
        this.defaultMessageEncoding = 'gsm-7';
        this.defaultMessageMaxLength = 960 - 6 * 7;
        let extCount = extendedCharactersCount(this.defaultMessage);
        this.defaultMessageTextLength = extCount + this.defaultMessage.length;
      }

      this.defaultMessageSmsCount = this.numberOfDefaultMessageSMS(this.defaultMessageEncoding);
    },
    numberOfDefaultMessageSMS(encoding) {
      let maxSingleSmsLength = 160;
      let internalLength = 153;
      if (encoding == 'ucs-2') {
        maxSingleSmsLength = 70;
        internalLength = 67;
      }
      let numberOfSms = 0;
      if (!this.defaultMessage || this.defaultMessageTextLength == 0) {
        return 0;
      } else if (this.defaultMessageTextLength <= maxSingleSmsLength) {
        numberOfSms = 1;
      } else {
        numberOfSms = this.defaultMessageTextLength / internalLength;
      }
      return Math.ceil(numberOfSms);
    },
    async getTextTemplate(id) {
      this.loading = true;
      return this.$store
        .dispatch('textTemplate/getTextTemplate', id)
        .then(() => {
          this.loading = false;
          // clear current
          this.defaultMessage = '';
          this.countryMessages.forEach((msg) => {
            this.$store.dispatch('alert/removeCountryMessage', msg);
          });
          let currentTemplate = this.$store.state.textTemplate.template;
          this.defaultMessage = currentTemplate.defaultMessage;
          currentTemplate.countryMessages.forEach((msg) => {
            // convert object
            let countryMessage = {
              message: msg.content,
              country: { code: msg.countryCode, name: msg.countryCode },
              encoding: msg.encoding,
              count: 0,
            };

            let currentCountry = this.byCountries.countries.find(
              (c) => c.country.code == msg.countryCode,
            );
            if (currentCountry) {
              countryMessage = {
                message: msg.content,
                country: {
                  code: msg.countryCode,
                  name: currentCountry.country.name,
                },
                encoding: msg.encoding,
                count: currentCountry.count,
              };
            } else {
              currentCountry = this.allCountries.find((c) => c.code == msg.countryCode);
              countryMessage = {
                message: msg.content,
                country: { code: msg.countryCode, name: currentCountry.name },
                encoding: msg.encoding,
                count: 0,
              };
            }

            this.$store.dispatch('alert/setCountryMessage', countryMessage);
          });
        })
        .catch((error) => {
          alert(error);
          this.loading = false;
        });
    },
    resetState() {
      this.$store.dispatch('alert/resetWizard');
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height-row {
  height: calc(100% - 154px);
  &.simulation-height {
    height: calc(100% - 211px);
  }
}
.wizard {
  position: relative;
  height: 100%;
}
.v-sheet.v-stepper ::v-deep {
  background-color: transparent;
  .v-stepper__header {
    box-shadow: none;
    height: 80px;
  }
  .v-stepper__items {
    height: 100%;
  }
  .v-stepper__wrapper {
    height: 100%;
  }
  .v-stepper__step {
    // padding: 24px 8px;
    padding: 8px 8px;
  }
  .v-stepper__step__step {
    height: 40px !important;
    width: 40px;
    font-size: 1.2em;
  }
  .v-stepper__header .v-divider {
    margin: 27px -66px 0;
    border-width: 2px;
  }
  .v-stepper__content {
    height: 100%;
    padding: 0 16px;
  }
  // .v-stepper__wrapper {
  //   padding-bottom: 8px ;
  // }
  .v-stepper__step--editable:hover {
    background: none;
  }
}
.countup-pre-text-icon {
  font-size: 30px;
  margin-right: 8px;
  padding: 0px 0px 5px 0;
}
.iCountUp {
  font-size: 2em;
  margin: 0 -1px;
  padding: 16px 16px;
  border: 1px solid #e6e6e6;
}
.subscriberCount {
  font-weight: 600;
}
.summaryCounter {
  margin: 0 -1px;
  padding: 0px 0px 8px 0px;
  text-align: center;
  border: 1px solid #bfbfbf;
  border-radius: 6px;

  .counter {
    font-size: 3em;
    text-align: center;
    font-weight: 600;
  }
  p {
    color: #676767;
    margin: 0;
  }
  i {
    font-size: 40px;
    padding: 8px 0 0 0;
  }
}

.v-expansion-panels ::v-deep {
  .v-expansion-panel {
    background: transparent !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 12px;
  }
}
// .error{
//   background-color: transparent !important;
//   color:red;
// }
.country-msg-card {
  padding: 8px 0px;
  margin: 0px 0px;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.send-icon {
  transform: rotate(25deg);
  margin-top: -4px;
}
.missing-cell-info {
  bottom: 8px;
  position: absolute;
  max-width: 450px;
  background-color: #e5f5ff;
  left: 8px;
  z-index: 200;
  border-radius: 8px !important;
  padding: 8px;
  border: #457b9d 1px solid;
}
.map {
  border-radius: 4px;
}
.summary-card {
  width: 400px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 200;
  background-color: #ffffffeb !important;
}

.default-message-card {
  //border: 1px solid #bfbfbf;
  //padding: 8px;
  border-radius: 6px;
  background-color: #f7f7f7;
}

.v-sheet.v-banner ::v-deep {
  .v-banner__wrapper {
    padding: 8px;
  }
}

.v-list ::v-deep {
  .v-list-item__content .v-list-item__mask {
    color: rgba(0, 0, 0, 0.9);
    background: #ffe586 !important;
  }
}
.icon-right {
  position: absolute;
  right: 8px;
}
.originator-input {
  max-width: 230px;
}
.text-template-selector {
  max-width: 350px;
}
</style>
