<template>
  <div class="wizard">
    <send-alert-wizard />
    <Confirm ref="confirm" />
  </div>
</template>

<script>
import SendAlertWizard from '@/components/SendAlertWizard.vue';
import Confirm from '@/components/dialog/Confirm.vue';

export default {
  name: 'NewAlert',
  components: {
    SendAlertWizard,
    Confirm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.alert.alertTarget != null) {
      this.$refs.confirm
        .open(
          this.$t('wizard.leaveWizardHeading'),
          this.$t('wizard.leaveWizardMsg'),
          this.$t('wizard.leaveWizard'),
          this.$t('wizard.stayOnPage'),
          { color: 'primary', width: '400px' },
        )
        .then((confirm) => {
          if (confirm) {
            // Reset store and navigate
            this.$store.dispatch('alert/resetWizard');
            next();
          }
        });
    } else {
      next();
    }
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.wizard {
  position: relative;
  height: 100%;
}
</style>
